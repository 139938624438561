import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import "./FlexibleTable.css"
import { AddCircle, Clear, OpenWith, Remove, Restore } from "@material-ui/icons"
import { Box } from "@material-ui/core"
import { SortIcon } from "ftl-uikit"
import { Dictionary, TableSettingsState } from "ftl-core"
import { IS_MOBILE_SCREEN_WIDTH } from "../../../../App"

type BaseColumn<DataType> = {
  name: string
  display: (element: DataType) => ReactNode
  ratio: number
  sortable?: {
    sortKey: string
  }
}

export type PermanentColumn<DataType> = {
  name?: string
  display: (element: DataType) => ReactNode
  pixelWidth: number
}

export type Column<DataType> = BaseColumn<DataType> & {
  disabled: boolean
}

export type FlexibleTableProps<DataType extends object> = {
  data: DataType[]
  possibleColumns: Column<DataType>[]
  permanentColumns: PermanentColumn<DataType>[]
  onRowClick?: (element: DataType) => void

  tableState?: TableSettingsState<DataType> | undefined
  setTableState?: ((newState: Partial<TableSettingsState<DataType>>) => void) | undefined
  flexibleColumnsLocalStorageName: string
  rowProps?: (row: DataType) => object | undefined
}

function FlexibleTable<DataType extends object>({
                                                  data,
                                                  possibleColumns,
                                                  permanentColumns,
                                                  onRowClick,
                                                  tableState,
                                                  setTableState,
                                                  flexibleColumnsLocalStorageName,
                                                  rowProps,
                                                }: FlexibleTableProps<DataType>) {

  const [currentColumns, setCurrentColumns] = useState<BaseColumn<DataType>[]>([])
  const [disabledColumns, setDisabledColumns] = useState<BaseColumn<DataType>[]>([])
  const [restartState, setRestartState] = useState(false)

  useEffect(() => {

    function initColumnsByDefault() {
      setCurrentColumns(possibleColumns.filter(column => !column.disabled))
      setDisabledColumns(possibleColumns.filter(column => column.disabled))
    }

    if (IS_MOBILE_SCREEN_WIDTH) {
      initColumnsByDefault()
      return
    }

    const jsonColumnsOrder = localStorage.getItem(flexibleColumnsLocalStorageName)
    if (jsonColumnsOrder) {

      try {
        const columnsOrder: string[] = JSON.parse(jsonColumnsOrder)
        if (columnsOrder.length === 0) {
          initColumnsByDefault()
          return
        }
        const newCurrentColumnsDict: Dictionary<BaseColumn<DataType>> = {}
        const newDisabledColumns: BaseColumn<DataType>[] = []
        possibleColumns.forEach(column => {
          let index = -1
          const found = columnsOrder.find((c, i) => {
            if (c === column.name) {
              index = i
              return true
            }
            return false
          })
          if (found)
            newCurrentColumnsDict[index] = column
          else
            newDisabledColumns.push(column)
        })

        const newCurrentColumns = []
        for (let i = 0; i < Object.keys(newCurrentColumnsDict).length; i++)
          if (newCurrentColumnsDict[i])
            newCurrentColumns.push(newCurrentColumnsDict[i])

        setCurrentColumns(newCurrentColumns)
        setDisabledColumns(newDisabledColumns)

      } catch (e) {
        initColumnsByDefault()
      }
    } else {
      initColumnsByDefault()
    }

  }, [flexibleColumnsLocalStorageName, possibleColumns, restartState])

  const [dragAndDrop, setDragAndDrop] = useState<{
    draggedFrom: number,
    draggedTo: number | null,
    isDragging: boolean,
    originalOrder: BaseColumn<DataType>[],
    updatedOrder: BaseColumn<DataType>[]
  }>({
    draggedFrom: 0,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  })
  const onDragStart = useCallback((e) => {

    const initialPosition = Number(e.currentTarget.dataset.position)
    setDragAndDrop({
      ...dragAndDrop,

      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: currentColumns,
    })

    e.dataTransfer.setData("text/html", "")
  }, [currentColumns, dragAndDrop])
  const onDragOver = useCallback((e) => {
    e.preventDefault()

    // Store the content of the original list
    // in this variable that we'll update
    let newList = dragAndDrop.originalOrder

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom

    // index of the drop area being hovered
    const draggedTo = Number(e.currentTarget.dataset.position)

    // get the element that's at the position of "draggedFrom"
    const itemDragged = newList[draggedFrom]

    // filter out the item being dragged
    const remainingItems = newList.filter((item, index) => index !== draggedFrom)

    // update the list
    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ]

    // since this event fires many times
    // we check if the targets are actually
    // different:
    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,

        // save the updated list state
        // we will render this onDrop
        updatedOrder: newList,
        draggedTo: draggedTo,
      })
    }
  }, [dragAndDrop])
  const onDrop = useCallback((e) => {

    setCurrentColumns(dragAndDrop.updatedOrder)

    // and reset the state of
    // the DnD
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: 0,
      draggedTo: null,
      isDragging: false,
    })
  }, [dragAndDrop])

  const [dropDownState, setDropDownState] = useState<{ top: number, left: number, index: number } | null>(null)
  const onOpenDropdownClick = useCallback((index: number, position: { x: number, y: number }) => {
    setDropDownState({
      left: position.x,
      top: position.y,
      index: index,
    })
  }, [])

  const onAddColumn = useCallback((columnName: string | undefined) => {

    if (!dropDownState)
      return

    let newColumn: BaseColumn<DataType> | undefined = undefined
    const newDisabledColumns = disabledColumns.filter(column => {
      if (column.name !== columnName)
        return true
      else {
        newColumn = column
        return false
      }
    })

    if (!newColumn)
      return

    const newCurrentColumns = [
      ...currentColumns.slice(0, dropDownState.index),
      newColumn,
      ...currentColumns.slice(dropDownState.index, currentColumns.length),
    ]

    setCurrentColumns(newCurrentColumns)
    setDisabledColumns(newDisabledColumns)
  }, [currentColumns, disabledColumns, dropDownState])

  const removeColumn = useCallback(index => {

    if (currentColumns.length === 1)
      return

    let removedColumn = undefined
    const newCurrentColumns = currentColumns.filter((column, i) => {
      if (index === i) {
        removedColumn = column
        return false
      } else
        return true
    })
    if (!removedColumn)
      return

    setCurrentColumns(newCurrentColumns)
    setDisabledColumns(disabledColumns.concat(removedColumn))

  }, [currentColumns, disabledColumns])

  const onHeaderClick = useCallback((column: BaseColumn<DataType>) => {
    if (column.sortable && setTableState && tableState)
      if (
        tableState.sortName === column.sortable.sortKey &&
        tableState.sortDirection === "DESC"
      )
        setTableState({
          sortDirection: "ASC",
          sortName: column.sortable.sortKey,
        })
      else if (
        tableState.sortName === column.sortable.sortKey &&
        tableState.sortDirection === "ASC"
      )
        setTableState({
          sortDirection: undefined,
          sortName: undefined,
        })
      else
        setTableState({
          sortDirection: "DESC",
          sortName: column.sortable.sortKey,
        })
  }, [setTableState, tableState])

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem(flexibleColumnsLocalStorageName, JSON.stringify(currentColumns.map(item => item.name)))
    }, 0)
  }, [currentColumns, flexibleColumnsLocalStorageName])

  const clearLocalStore = useCallback(() => {
    localStorage.removeItem(flexibleColumnsLocalStorageName)
  }, [flexibleColumnsLocalStorageName])

  const dataDisplay = useMemo(() => {
    if(!Array.isArray(data))
      return <></>
    return data ? data.map(item =>
      <tr
        className={"row" + (onRowClick ? " clickable-row" : "")}
        style={rowProps ? rowProps(item) : undefined}
        onClick={onRowClick ? (() => onRowClick(item)) : undefined}
      >
        {
          currentColumns.map(column => <td className={"cell"}>{column.display(item) ?? "—"}</td>)
        }
        {
          permanentColumns.map(column => <td className={"permanent-cell"}>{column.display(item)}</td>)
        }
      </tr>,
    ) : []
  }    , [currentColumns, data, onRowClick, permanentColumns, rowProps])

  return <>
    {
      !IS_MOBILE_SCREEN_WIDTH && disabledColumns.length !== 0 &&
      <div className={"add-column-flexible-table"} id={"first-add-column"} onClick={(e) => {
        e.stopPropagation()
        onOpenDropdownClick(0, { x: e.clientX, y: e.clientY })
      }}>
        <AddCircle />
      </div>
    }

    <div className={"before-table-container"}>

      <table className={"flexible-table"}>

        <tr>
          {
            currentColumns.map(
              (column, index) => {

                let ratiosSum = 0
                currentColumns.forEach(column => ratiosSum += column.ratio)

                return <>

                  <th
                    style={{ width: `${column.ratio / ratiosSum * 100}%` }}
                    data-position={index}
                    className={"title" + (dragAndDrop.draggedTo === index ? " drop-area" : "") + (column.sortable ? " sortable-title" : "")}

                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    draggable

                    onClick={() => onHeaderClick(column)}
                  >
                    {
                      !IS_MOBILE_SCREEN_WIDTH &&
                      <div className={"icons-holder"}>
                        {
                          currentColumns.length !== 1 &&
                          <>
                            <div className={"icon-holder"} onClick={(e) => {
                              e.stopPropagation()
                              removeColumn(index)
                            }}>
                              <Clear />
                            </div>
                          </>
                        }
                      </div>
                    }

                    {
                      !IS_MOBILE_SCREEN_WIDTH && disabledColumns.length !== 0 &&
                      <div className={"add-column-flexible-table"} onClick={(e) => {
                        e.stopPropagation()
                        onOpenDropdownClick(index + 1, { x: e.clientX, y: e.clientY })
                      }}>
                        <AddCircle />
                      </div>
                    }

                    <Box
                      display={column.sortable ? "inline-flex" : "block"}
                      overflow="hidden"
                      flexDirection={"row"}
                    >
                      {column.name}
                      {column.sortable && tableState && (
                        <SortIcon
                          sort={
                            tableState.sortName === column.sortable.sortKey
                              ? tableState.sortDirection
                              : undefined
                          }
                        />
                      )}
                    </Box>

                  </th>
                </>
              })
          }
          {
            permanentColumns.map(
              (column) => {

                return <>
                  <th
                    style={{ width: column.pixelWidth }}
                    className={"title"}
                  >
                    <span className={"data"}>
                      {column.name}
                    </span>
                  </th>
                </>
              })
          }

        </tr>
        {
          dataDisplay
        }
      </table>
      {
        dropDownState &&
        <div
          style={dropDownState}
          className={"dropdown-column-select"}
          onMouseLeave={(e) => {
            setDropDownState(null)
          }}
        >
          <ul
          >
            {
              disabledColumns.map(column =>
                <li onClick={() => {
                  onAddColumn(column.name)
                  setDropDownState(null)
                }}>
                  {column.name}
                </li>,
              )
            }
          </ul>
          <div className={"settings"} title={"Восстановить столбцы по умолчанию"}>
            <Restore onClick={() => {
              clearLocalStore()
              setDropDownState(null)
              setRestartState(!restartState)
            }} />
          </div>
        </div>
      }
    </div>
  </>
}

export default FlexibleTable