import { AuthClient, createRepositoryInstance, createUtilsRepository } from "ftl-core"
import Cookies from "js-cookie"
import { SignInOptions, SignInResponse } from "ftl-core/dist/repository/auth/repositories/AuthClient"
import { AxiosResponse } from "axios"

enum Versions {
  v1 = "v1",
  v2 = "v2"
}

export const authApiBaseUrl = process.env.REACT_APP_FTL_AUTH_BASE_URL
  ? process.env.REACT_APP_FTL_AUTH_BASE_URL
  : "http://localhost:9102"
export const baseUrl = process.env.REACT_APP_FTL_CRM_BASE_URL
  ? process.env.REACT_APP_FTL_CRM_BASE_URL
  : "a___a"

export const apiBaseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? `${baseUrl}/api/admin/${Versions.v1}`
    : `${baseUrl}/api/admin/${Versions.v1}`

export const apiBaseUrlV2 =
  process.env.REACT_APP_ENV === "dev"
    ? `${baseUrl}/api/admin/${Versions.v2}`
    : `${baseUrl}/api/admin/${Versions.v2}`

export const dadataBaseUrl = process.env.REACT_APP_DADATA_BASE_URL ? `${process.env.REACT_APP_DADATA_BASE_URL}/api/admin/v1` : "dadata___832938"
export const iikoTransportBaseUrl = process.env.REACT_APP_IIKO_TRANSPORT_BASE_URL ?
  `${process.env.REACT_APP_IIKO_TRANSPORT_BASE_URL}/api/admin/v1` :
  "transport_url_error_328740"

export const repository = createRepositoryInstance({
  baseUrl: apiBaseUrl,
  authErrors: {
    reasonKey: "reason",
    tokenExpired: ["ACCESS_TOKEN_EXPIRED"],
    permissionInsufficient: ["CREDENTIAL_PERMISSION_INSUFFICIENT"],
    needToLogin: [
      "CREDENTIAL_BLOCKED",
      "ACCESS_TOKEN_INACTIVATED",
      "ACCESS_TOKEN_NOT_EXIST",
      "ACCESS_TOKEN_REFRESHED",
      "ACCESS_TOKEN_MODIFIED",
      "ACCESS_TOKEN_MALFORMED",
    ],
  },
  tokenRefresh: () => AuthAPI.refresh(),
})


export const AuthAPI = new AuthClient(
  repository,
  {
    signIn: "noUrl320472", //`${authApiBaseUrl}/not-secure/api/v1/auth/login/email`,
    signOut: "noUrl8439108", //`${authApiBaseUrl}/not-secure/api/v1/auth/logout`,
    refresh: `${authApiBaseUrl}/not-secure/api/v1/auth/refresh`,
  },
  {
    loginPropName: "email",
  },
)

AuthAPI.signOut = async (): Promise<any> => {
  try {
    const response = await repository.post(`${authApiBaseUrl}/not-secure/api/v1/auth/logout`, {
      accessToken: Cookies.get("accessToken"),
    })
    Cookies.remove("accessToken")
    Cookies.remove("refreshToken")
    return response
  } catch (error) {
    throw error
  }
}

AuthAPI.signIn = async (
  login: string,
  password: string,
  options?: Partial<SignInOptions> | undefined,
): Promise<AxiosResponse<SignInResponse>> => {
  try {
    const fetchDataOptions = {
      loginPropName: "username",
      passwordPropName: "password",
      ...options,
    }

    const response = await repository.post(`${authApiBaseUrl}/not-secure/api/v1/auth/single-factor/one-step/with-password/username`, {
      [fetchDataOptions.loginPropName]: login,
      [fetchDataOptions.passwordPropName]: password,
      options: options,
      ip: "127.0.0.1",
      applicationDetails: {
        name: "NO_NAME",
        platform: "NO_PLATFORM",
        versionName: "NO_VERSION",
      },
      deviceId: "NO_DEVICE",
    })
    Cookies.set("accessToken", response.data.result.accessToken)
    Cookies.set("refreshToken", response.data.result.refreshToken)

    return response
  } catch (error) {
    Cookies.remove("accessToken")
    Cookies.remove("refreshToken")
    throw error
  }
}

export const UtilsAPI = createUtilsRepository(repository)

