import "./Anekdoter.css"
import { Clear } from "@material-ui/icons"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"

const Anekdoter = () => {

  const [className, setClassName] = useState("anekdoter anekdoter-getting-ready-to-come-up")
  const [joke] = useState<string[]>([
    "И о курсе валют.\n" +
    "Доллар упал, а где он лежит никто не знает.",
    "Помнится, однажды жена начала принимать фенотропил для улучшения работы мозга и через две недели бросила меня.",
    "- Вы чем интересуетесь?\n" +
    "- Политикой.\n" +
    "- Активный или пассивный?\n" +
    "- В смысле?\n" +
    "- Ну, депутат или так, телевизор смотрите?",
    "Вторая неделя карантина. Разговорился с пауком. Хорошо пообщались. Он тоже веб-дизайнер.",
    "Сижу в кафе. Подходит парень и говорит: - Девушка, можно я за вас заплачу?…\n" +
    "Ну я не растерялась — достала квитанции за отопление, газ, свет, воду…",
  ])

  const [doWeShowToday, setDoWeShowToday] = useState(false)

  useEffect(() => {
    const lastJoke = localStorage.getItem("last-joke")
    if (!lastJoke ) {
      localStorage.setItem("last-joke", new Date().toDateString())
      setDoWeShowToday(true)
    } else {
      if (lastJoke === new Date().toDateString())
        setDoWeShowToday(false)
      else{
        setDoWeShowToday(true)
        localStorage.setItem("last-joke", new Date().toDateString())
      }
    }

  }, [])

  useEffect(() => {
    setTimeout(() => {
      setClassName("anekdoter")
    }, 10000)
  }, [])

  return <>
    {doWeShowToday && <div className={className}>
      <div className={"header"}>
        <h3>Анекдот дня</h3>
        <Clear className={"cross"} onClick={() => {
          setClassName("anekdoter hidden-anekdot")
        }} />
      </div>
      <p className={"content"} id={"sodufhosd"}>
        {joke[Math.floor(Math.random() * joke.length)]}
      </p>
    </div>
    }
  </>
}

export default Anekdoter