import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory"

import {
  AttachMoney,
  Ballot,
  Category, Extension,
  Fastfood,
  Feedback, History,
  Instagram,
  LocalActivity,
  LocalOffer,
  MenuBook,
  Notifications, Place, Public, RateReview, Restaurant, Satellite,
  ViewCarousel,
  Wc,
} from "@material-ui/icons"

import { MapIcon, OrderIcon as OrderIcon_, CityIcon as CityIcon_ } from "ftl-uikit"

export const CountryIcon = () => {
  return <Public />
}

export const CityIcon = () => {
  return <CityIcon_ />
}

export const RestaurantIcon = () => {
  return <StoreMallDirectoryIcon />
}

export const DeliveryZoneIcon = () => {
  return <Place />
}

export const CatalogIcon = () => {
  return <MenuBook />
}

export const ProductIcon = () => {
  return <Fastfood />
}

export const DishIcon = () => {
  return <Fastfood />
}

export const ConstructorIcon = () => {
  return <Extension />
}

export const NomenclatureIcon = () => {
  return <Ballot />
}

export const PromotionIcon = () => {
  return <LocalActivity />
}

export const BannerGroupIcon = () => {
  return <ViewCarousel />
}

export const BannerIcon = () => {
  return <Satellite />
}

export const StoryIcon = () => {
  return <Instagram />
}

export const OrderIcon = () => {
  return <OrderIcon_ />
}

export const CustomerIcon = () => {
  return <Wc />
}

export const FeedbackIcon = () => {
  return <Feedback />
}

export const FeedbackReasonIcon = () => {
  return <RateReview />
}

export const CategoryIcon = () => {
  return <Category />
}

export const PriceCategoryIcon = () => {
  return <LocalOffer />
}

export const PriceIcon = () => {
  return <AttachMoney />
}

export const LegalIcon = () => {
  return <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false"
              aria-hidden="true" viewBox="0 0 24 24" data-testid="HistoryEduIcon">
    <path
      d="M9 4v1.38c-.83-.33-1.72-.5-2.61-.5-1.79 0-3.58.68-4.95 2.05l3.33 3.33h1.11v1.11c.86.86 1.98 1.31 3.11 1.36V15H6v3c0 1.1.9 2 2 2h10c1.66 0 3-1.34 3-3V4H9zm-1.11 6.41V8.26H5.61L4.57 7.22a5.07 5.07 0 0 1 1.82-.34c1.34 0 2.59.52 3.54 1.46l1.41 1.41-.2.2c-.51.51-1.19.8-1.92.8-.47 0-.93-.12-1.33-.34zM19 17c0 .55-.45 1-1 1s-1-.45-1-1v-2h-6v-2.59c.57-.23 1.1-.57 1.56-1.03l.2-.2L15.59 14H17v-1.41l-6-5.97V6h8v11z"></path>
  </svg>
}

export const NotificationIcon = () => {
  return <Notifications />
}

export const ComplectationIcon = () => {
  return <Restaurant />
}


export const PumpkinIcon = () => {
  return <svg style={{ transform: "translateY(-2px)"}} xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="0 0 24 24" data-name="Layer 1"
              id="Layer_1"><title />
    <path
      d="M12.3232,8.03613l-1.3183-.71484a3.13222,3.13222,0,0,0-.8584-3.76563l1.0068-1.11132A4.6226,4.6226,0,0,1,12.3232,8.03613Z"
      style={{ fill: "#c78657" }} />
    <path
      d="M7.125,6h-.25C4.1826,6,2,9.35785,2,13.5S4.1826,21,6.875,21h.25C9.8174,21,12,17.6422,12,13.5S9.8174,6,7.125,6Z"
      style={{ fill: "#ffab66" }} />
    <path
      d="M17.125,6h-.25C14.1826,6,12,9.35785,12,13.5S14.1826,21,16.875,21h.25C19.8174,21,22,17.6422,22,13.5S19.8174,6,17.125,6Z"
      style={{ fill: "#ffab66" }} />
    <path
      d="M12.0758,6h-.1516C9.20465,6,7,9.35785,7,13.5S9.20465,21,11.9242,21h.1516C14.7954,21,17,17.6422,17,13.5S14.7954,6,12.0758,6Z"
      style={{ fill: "#ffab66" }} />
    <polygon points="10 12 5 12 6 9 10 12" style={{ fill: "#fff" }} />
    <polygon points="14 12 19 12 18 9 14 12" style={{ fill: "#fff" }} />
    <polygon points="13.5 14 10.5 14 12 12 13.5 14" style={{ fill: "#fff" }} />
    <polygon
      points="15.111 17.828 12 16.791 8.889 17.828 5.584 15.624 6.416 14.376 9.111 16.172 12 15.209 14.889 16.172 17.584 14.376 18.416 15.624 15.111 17.828"
      style={{ fill: "#fff7c2" }} />
  </svg>
}