import CrocComponent from "./CrocComponent"
import { useEffect, useState } from "react"

const CrocLauncher = ({crocsWords}: {crocsWords: string}) => {

  const [showCroc, setShowCroc] = useState<boolean>(false)

  useEffect(() => {
    document.addEventListener("keydown", function(e) {
      if (!showCroc) {
        if (
          e.key === "c" &&
          e.altKey
        ) {
          setShowCroc(true)
          setTimeout(() => {
            setShowCroc(false)
          }, 8000)
        }
      }
    }, false)
  })

  return <>
    {
      showCroc && (<>
        <CrocComponent />
        <p id={"crocsWords"}>{crocsWords}</p>
      </>)
    }
  </>
}

export default CrocLauncher