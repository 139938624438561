import { Routes } from "./Routes"

import { theme } from "ftl-uikit"

import { createStore } from "redux"
import {
  basicReducer,
  initialState,
  logOutAction,
} from "ftl-core"
import CrocLauncher from "./lib/components/croc/CrocLauncher"
import Anekdoter from "./lib/components/anekdoter/Anekdoter"
import { UIMain } from "ftl-dashboards-templates"
import { AuthAPI } from "./repository/common"
import {
  BannerGroupIcon,
  CatalogIcon, CategoryIcon,
  CityIcon, CountryIcon, CustomerIcon,
  DeliveryZoneIcon, FeedbackIcon, LegalIcon,
  NomenclatureIcon, NotificationIcon, OrderIcon, PriceCategoryIcon, PriceIcon,
  ProductIcon, PromotionIcon,
  RestaurantIcon, StoryIcon,
} from "./pages/commons/icons/icons"
import { CardGiftcard, Flare, MoreHoriz, Public, Star } from "@material-ui/icons"
import VersionNewsShower from "./lib/components/versionNewsShower/VersionNewsShower"
import React from "react"

const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

export const IS_MOBILE_SCREEN_WIDTH = window.innerWidth < 960
const sideMenuItems = [
  {
    icon: <> <CardGiftcard style={{ color: "rgb(213,21,119)" }} /> <Star style={{ color: "rgb(213,21,119)", transform: "scale(0.65)" }} /></>,
    label: "Новогодняя акция",
    to: "/special-date-promotion",
  },
  {
    icon: <CountryIcon />,
    label: "Страны",
    to: "/countries",
  },
  {
    icon: <CityIcon />,
    label: "Города",
    to: "/cities",
  },
  {
    icon: <RestaurantIcon />,
    label: "Рестораны",
    to: "/restaurants",
  },
  {
    icon: <DeliveryZoneIcon />,
    label: "Зоны доставки",
    to: "/cities-delivery-zones",
  },
  {
    icon: <CatalogIcon />,
    label: "Каталоги",
    to: "/catalogs",
  },
  {
    icon: <ProductIcon />,
    label: "Продукты",
    to: "/products",
    items: [
      {
        label: "Блюда",
        to: "/dishes",
      },
      {
        label: "Конструкторы",
        to: "/constructors",
      },
      {
        label: "Комплектация и столовые приборы",
        to: "/complectations",
      },
      {
        label: "Синхронизация",
        to: "/product-sync",
      },
    ],
  },
  {
    icon: <NomenclatureIcon />,
    label: "Номенклатура",
    to: "/nomenclatures",
  },
  {
    icon: <PromotionIcon />,
    label: "Акции",
    to: "/promotions",
    items: [
      {
        label: "Простые акции",
        to: "/promotions",
      },
      {
        label: "Накопительные акции",
        to: "/gift-repurchases",
      },
    ],
  },
  {
    icon: <BannerGroupIcon />,
    label: "Управление баннерами",
    to: "/banners-control",
    items: [
      {
        label: "Группы баннеров",
        to: "/banner-groups",
      },
      {
        label: "Баннеры",
        to: "/banners",
      },
    ],
  },
  {
    icon: <StoryIcon />,
    label: "Управление сторисами",
    to: "/stories-control",
    items: [
      {
        label: "Сторис",
        to: "/stories-groups",
      },
      {
        label: "Слайды для сторис",
        to: "/stories",
      },
    ],
  },
  {
    icon: <OrderIcon />,
    label: "Заказы",
    to: "/orders",
    items: [
      {
        label: "Заказы",
        to: "/orders",
      },
      {
        label: "Необработанные заказы",
        to: "/with-external-problem",
      },
    ],
  },
  {
    icon: <CustomerIcon />,
    label: "Гости",
    to: "/customers-group",
    items: [
      {
        label: "Гости",
        to: "/customers",
      },
      {
        label: "Архивированные гости",
        to: "/archived-customers",
      },
    ],
  },
  {
    icon: <FeedbackIcon />,
    label: "Отзывы",
    to: "/feedback-messages-common",
    items: [
      {
        label: "Отзывы",
        to: "/feedback-messages",
      },
      {
        label: "Причины отзывов",
        to: "/feedback-reasons",
      },
    ],
  },
  {
    icon: <CategoryIcon />,
    label: "Категории",
    to: "/categories",
  },
  {
    icon: <PriceCategoryIcon />,
    label: "Ценовые категории",
    to: "/price-categories",
  },
  {
    icon: <PriceIcon />,
    label: "Цены",
    to: "/prices",
  },
  {
    icon: <LegalIcon />,
    label: "Юр. лица",
    to: "/legals",
  },
  {
    icon: <NotificationIcon />,
    label: "Уведомления",
    to: "/notifications",
  },
  {
    icon: <MoreHoriz />,
    label: "Другое",
    to: "/other",
    items: [
      {
        label: "Платёжные карты",
        to: "/payment-cards",
      },
      {
        label: "Управление версиями",
        to: "/versions-management",
      },
      {
        label: "Шаблон комментария заказа",
        to: "/order-comment-template",
      },
      {
        label: "Модерация дней рождения",
        to: "/birthday-moderation",
      },
      {
        label: "SMS",
        to: "/sms",
      },
      {
        label: "Настройка информационных баннеров",
        to: "/city-stops",
      },
      {
        label: "Персональные предложения",
        to: "/personal-offers",
      },
      {
        label: "Фильтрация типов оплат",
        to: "/payment-type-filters",
      },
      {
        label: "Управление фичами",
        to: "/features-info-management",
      },
      {
        label: "Настройки заказов",
        to: "/order-settings",
      },
      {
        label: "Release Notes",
        to: "/release-notes",
      },
      {
        label: "Настройки",
        to: "/settings",
      },
    ],
  },
].concat(process.env.REACT_APP_ENV === "dev" ? {
  icon: <></>,
  label: "Тест",
  to: "/test",
} : [])

export const App = () => {
  return (
    <>
      <UIMain
        redirectOnAuthUrl="/restaurants"
        project={{
          title: "FEat",
        }}
        theme={theme}
        AuthClient={AuthAPI}
        StoreProviderProps={{
          store,
          getProfile: () => {
            //заглушка: единственный способ избежать вытягивание профиля при загрузке приложения
            return Promise.resolve({
              data: {
                result: {
                  id: "",
                  createdAt: "",
                  email: "",
                  firstName: "",
                  lastName: "",
                  accountStatus: "",
                },
              },
              status: 200,
              statusText: "OK",
              headers: {},
              config: {},
            })
          },
        }}
        BaseLayoutProps={{
          onSignOut: () => store.dispatch(logOutAction),
          profileMenuItems: sideMenuItems,
          sideMenuItems: sideMenuItems,
        }}
      >
        <Routes />
      </UIMain>

      <CrocLauncher crocsWords={"Здравия желаю..."} />
      {
        new Date().getMonth() === 3 && new Date().getDate() === 1 &&
        <Anekdoter />
      }

      <VersionNewsShower />
    </>
  )
}

export default App
