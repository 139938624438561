import { ReactNode } from "react"
import { MoreHoriz, Settings, Sync } from "@material-ui/icons"
import { history } from "ftl-core"
import FlexibleTable from "./pages/commons/components/FlexibleTable/FlexibleTable"

export type Feature = {
  description: ReactNode
}

//
//  Добавлять новые фичи только в конец!
//
export const features: Feature[] = [
  {
    description: "Карта для зон доставок",
  },
  {
    description: <>Подраздел "Настройки" в разделе "Другое" (<MoreHoriz />)</>,
  },
  {
    description: <>Исследование загруженности заказов <span onClick={() => history.push("/orders/workload")} style={{
      color: "blue",
      cursor: "pointer",
    }}>перейти</span></>,
  },
  {
    description: <>Теперь ты можешь добавлять продукт в глобальный стоп лист, прямо в списке продуктов</>,
  },
  {
    description: <>Гибкие таблицы: добавляй, перетаскивай и удаляй столбцы для более удобной работы <FlexibleTable
      data={[
        {
          name: "Петя",
          age: 23,
          property: "Программист",
        },
        {
          name: "Настя",
          age: 21,
          property: "Контент-менеджер",
        },
      ]}
      possibleColumns={[
        {
          name: "Имя",
          disabled: false,
          display: element => element.name,
          ratio: 1,
        },
        {
          name: "Возраст",
          disabled: false,
          display: element => element.age,
          ratio: 1,
        },
        {
          name: "Должность",
          disabled: true,
          display: element => element.property,
          ratio: 1,
        }
      ]}
      permanentColumns={[]}
      flexibleColumnsLocalStorageName={'test-features-flexible-table'}
    />
    </>,
  },
  {
    description: <>Добавлен раздел: Настрока заказов. "<Settings />" значёк на странице заказов</>
  },
  {
    description: <>Расширеные фильтры для Заказов и Гостей</>
  },
  {
    description: <>Добавлены новые типы условий для персональных предложений</>
  },
  {
    description: <>Новое: Расписание работы для терминала</>
  },
  {
    description: <>Новый раздел: "Фильтрация типов оплат" <span onClick={() => history.push("/payment-type-filters")} style={{
      color: "blue",
      cursor: "pointer",
    }}>тут</span></>
  },
  {
    description: <>Большая карточка товара! Доступна загрузка видео для блюд</>
  }
]