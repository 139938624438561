import "./VersionNewsShower.css"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons"
import { features } from "../../../features"

const VersionNewsShower = () => {

  const [visible, setVisible] = useState(false)
  const [animationClass, setAnimationClass] = useState("vns-main-box-appear")

  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0)
  const [lookFrom, setLookFrom] = useState(0)
  const [lookTo, setLookTo] = useState(0)
  const [currentNews, setCurrentNews] = useState<ReactNode>(<></>)
  const [noShowAnymore, setNoShowAnymore] = useState(false)

  useEffect(() => {

    const lastSeenFeatureString = localStorage.getItem("lastSeenFeature")
    let lastSeenFeature = -1
    if (lastSeenFeatureString === "disabled")
      return
    if (lastSeenFeatureString)
      lastSeenFeature = Number(lastSeenFeatureString)

    if (lastSeenFeature < features.length - 1) {
      setLookFrom(lastSeenFeature + 1)
      setLookTo(features.length)
      setCurrentFeatureIndex(lastSeenFeature + 1)
      setVisible(true)
    }

  }, [])

  useEffect(() => {
    setCurrentNews(features[currentFeatureIndex].description)
    if (!localStorage.getItem("lastSeenFeature") || currentFeatureIndex > Number(localStorage.getItem("lastSeenFeature")))
      localStorage.setItem("lastSeenFeature", currentFeatureIndex + "")
  }, [currentFeatureIndex])

  const onClose = useCallback(() => {
    setAnimationClass("vns-main-box-disappear")
    setTimeout(() => {
      setVisible(false)
    }, 6000)
    if (noShowAnymore)
      localStorage.setItem("lastSeenFeature", "disabled")
  }, [noShowAnymore])

  return <>
    {
      visible && <div className={"vns-main-box " + animationClass}>
        <div className={"vns-window-roof"}>
          <div className={"cross"} onClick={onClose}>
            <Close className={"cross-icon"} />
          </div>
        </div>

        <div className={"content-box"}>
          <p>Что нового</p>
          <div className={"new"}>
            {currentNews}
          </div>
        </div>

        <div className={"bottom-line"}>
          <div>
            <input type={"checkbox"} className={"checkbox"} onChange={(e) => {
              setNoShowAnymore(e.target.checked)
            }} /> больше не показывать
          </div>
          <div className={"arrow-container"}>
            <div className={"ok-button"} onClick={() => {
              localStorage.setItem('lastSeenFeature', (lookTo - 1) + '')
              onClose()
            }}>
              Всё ясно
            </div>
            <ArrowBackIos
              onClick={() => {
                if (currentFeatureIndex > lookFrom)
                  setCurrentFeatureIndex(currentFeatureIndex - 1)
              }}
            />
            <div className={"news-count-shower"}>{currentFeatureIndex - lookFrom + 1}/{lookTo - lookFrom}</div>
            <ArrowForwardIos
              onClick={() => {
                if (currentFeatureIndex < lookTo - 1)
                  setCurrentFeatureIndex(currentFeatureIndex + 1)
              }}
            />
          </div>
        </div>
      </div>
    }
  </>
}
export default VersionNewsShower